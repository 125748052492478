// Center
.u-mx--auto {
  margin-left: auto;
  margin-right: auto;
}

// Base margin towards header
.u-mt--base-large {
  margin-top: rem(150);

  @media ($min-ms) {
    margin-top: calc(#{rem(170)} + (#{rem(60)} * var(--logo-scale) * 0.01));
  }
}

.u-mt--base-small {
  margin-top: rem(110);

  @media ($min-ms) {
    margin-top: calc(#{rem(120)} + (#{rem(60)} * var(--logo-scale) * 0.01));
  }
}

.u-mt--base-special {
  @media ($max-ms) {
    margin-top: rem(110);
  }

  @media ($max-s) {
    margin-top: rem(80);
  }
}

@for $i from 1 through 10 {
  .u-mt--#{$i} {
    margin-top: var(--space-#{$i});
  }

  .u-mb--#{$i} {
    margin-bottom: var(--space-#{$i});
  }
}
