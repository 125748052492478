.o-nav-icon {
  width: 100%;
  height: auto;
  display: block;
  fill: none;
  pointer-events: none;
  stroke-width: 2.25;
  stroke: currentColor;
}

.o-nav-icon__line {
  stroke-dasharray: 24;
  stroke-dashoffset: 0;
  opacity: 1;
  transition: stroke-dashoffset 300ms $ease-out, opacity 100ms $ease-fade;
  transition-delay: 200ms, 200ms;

  &:nth-child(2) {
    transition-delay: 300ms, 300ms;
  }

  &:nth-child(3) {
    transition-delay: 400ms, 400ms;
  }
}

.o-nav-icon--active .o-nav-icon__line {
  stroke-dashoffset: 24;
  opacity: 0;
  transition-delay: 0ms, 200ms;

  &:nth-child(1) {
    transition-delay: 200ms, 400ms;
  }

  &:nth-child(2) {
    transition-delay: 100ms, 300ms;
  }
}

.o-nav-icon__cross {
  stroke-dasharray: 33.941123962402344;
  stroke-dashoffset: 33.941123962402344;
  opacity: 0;
  transition: stroke-dashoffset 300ms $ease-out, opacity 100ms $ease-fade;
  transition-delay: 0ms, 200ms;

  &:nth-child(5) {
    transition-delay: 100ms, 300ms;
  }
}

.o-nav-icon--active .o-nav-icon__cross {
  stroke-dashoffset: 0;
  opacity: 1;
  transition-delay: 300ms, 300ms;

  &:nth-child(5) {
    transition-delay: 400ms, 400ms;
  }
}
