.l-teaser {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 4rem 3rem;

  @media ($min-ms) {
    grid-template-columns: repeat(auto-fit, minmax(rem(440), 1fr));
    grid-row-gap: 6rem;
  }
}

.l-teaser__item {
  position: relative;
}

.l-teaser__link {
  &::after {
    @include extended-clickarea(0, 0);
  }
}

.l-teaser__more {
  font-weight: bold;
  text-decoration: underline;

  .l-teaser__item:hover &,
  .l-teaser__item:focus-within & {
    text-decoration: none;
    color: $color-blue;
  }
}
