$font-url: '../fnt/munken-sans-';
$font-family: 'Munken Sans Web';

@font-face {
  font-family: $font-family;
  font-weight: normal;
  font-style: normal;
  font-display: swap;
  src: url($font-url + 'regular.woff2') format('woff2'),
    url($font-url + 'regular.woff') format('woff');
}

@font-face {
  font-family: $font-family;
  font-weight: 700;
  font-style: normal;
  font-display: swap;
  src: url($font-url + 'medium.woff2') format('woff2'),
    url($font-url + 'medium.woff') format('woff');
}

@font-face {
  font-family: $font-family;
  font-weight: 900;
  font-style: normal;
  font-display: swap;
  src: url($font-url + 'bold.woff2') format('woff2'),
    url($font-url + 'bold.woff') format('woff');
}
