// Less BEM used, since nav styles are fixed and not used elsewhere
.c-nav {
  &--active,
  &--black {
    color: $color-black;
  }
}

.c-nav__toggle {
  display: block;
  box-sizing: content-box;
  width: rem(32);
  height: rem(32);
  padding: rem(6);
  position: fixed;
  z-index: 2;
  top: rem(17);
  transition: color 150ms $ease-fade; // SVG transition

  @media ($min-s) {
    top: 1.875rem;
  }
}

.c-nav__wrapper {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  min-height: 100vh;
  min-height: calc(var(--vh, 1vh) * 100); // Custom --vh to fix iOS display
  background-color: $color-gray-bg;
  padding-top: 3rem;
  padding-bottom: 4rem;
  padding-left: var(--padding-x-base);
  padding-right: var(--padding-x-base);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @media ($min-s) {
    padding-top: 4rem;
    padding-bottom: 5rem;
  }

  opacity: 0;
  visibility: hidden;
  //transition: opacity 150ms $ease-fade, visibility 0ms linear 150ms;

  .c-nav--active & {
    opacity: 1;
    visibility: visible;
    //transition-delay: 0ms, 0ms;
  }
}

.c-nav__lists {
  > * + * {
    margin-top: 1.75rem;
  }

  li {
    overflow: hidden;
  }

  a {
    position: relative;
    display: inline-block;

    &::after {
      @include extended-clickarea($y: 4);
    }

    &:hover,
    &:focus,
    &.active {
      text-decoration: underline;
      text-decoration-thickness: 0.075em;
      text-underline-offset: 0.1em;
    }
  }
}

.c-nav__primary {
  font-size: rem(30);

  @media ($min-s) {
    font-size: rem(40);
  }
}

.c-nav__secondary {
  font-size: rem(16);
  font-weight: bold;

  li + li {
    margin-top: 0.25rem;
  }
}
