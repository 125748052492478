// Colors
$color-black: rgb(0, 0, 0);
$color-white: rgb(255, 255, 255);
$color-gray-bg: rgb(247, 247, 247);
$color-gray-light: rgb(230, 230, 230);
$color-gray-medium: rgb(177, 177, 177);
$color-blue: rgb(20, 60, 156);

// Breakpoints
$xs: em(360);
$s: em(480);
$ms: em(720);
$m: em(960);
$ml: em(1280);
$l: em(1440);
$xl: em(1680);
$xxl: em(1920);

$max-xs: 'max-width: #{$xs - (1/16)}';
$min-xs: 'min-width: #{$xs}';
$max-s: 'max-width: #{$s - (1/16)}';
$min-s: 'min-width: #{$s}';
$max-ms: 'max-width: #{$ms - (1/16)}';
$min-ms: 'min-width: #{$ms}';
$max-m: 'max-width: #{$m - (1/16)}';
$min-m: 'min-width: #{$m}';
$max-ml: 'max-width: #{$ml - (1/16)}';
$min-ml: 'min-width: #{$ml}';
$max-l: 'max-width: #{$l - (1/16)}';
$min-l: 'min-width: #{$l}';
$max-xl: 'max-width: #{$xl - (1/16)}';
$min-xl: 'min-width: #{$xl}';
$max-xxl: 'max-width: #{$xxl - (1/16)}';
$min-xxl: 'min-width: #{$xxl}';

// Fonts
$font-family-default: 'Munken Sans Web', -system-ui, -apple-system,
  BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif;

// Font sizes
$font-size-default: (rem(18), rem(20));
$font-size-large: (rem(24), rem(30));
$font-size-small: (rem(15), rem(16));
$font-size-hl-1: (rem(32), rem(40));
$font-size-hl-2: (rem(24), rem(30));
$font-size-hl-3: (rem(20), rem(24));
$font-size-quote: (rem(20), rem(24));

// Line heights
$line-height-default: 1.5; // change will affect special type layouts
$line-height-medium: 1.35;
$line-height-narrow: 1.2;

// Letter spacings
$letter-spacing-default: 0;
$letter-spacing-wide: 0.05em;

// Widths
$max-width-medium: rem(816);
$max-width-narrow: rem(580);

// Shadows
$box-shadow-default: 2px 2px 4px rgba($color-black, 0.1),
  3px 2px 12px rgba($color-black, 0.15);

// Easings
$ease-out: cubic-bezier(0.23, 1, 0.32, 1);
$ease-in: cubic-bezier(0, 0, 0.2, 1);
$ease-in-out: cubic-bezier(0.55, 0, 0.1, 1);
$ease-fade: cubic-bezier(0.25, 0, 0.25, 1);

// Use css vars for less media queries in util classes
:root {
  // Horizontal paddings
  --padding-x-base: 1rem;

  @media ($min-s) {
    --padding-x-base: 3rem;
  }

  @media ($min-ml) {
    --padding-x-base: 5rem;
  }

  // Vertical spacing
  --space-base: 0.75rem;

  @media ($min-ms) {
    --space-base: 1rem;
  }

  @for $i from 1 through 10 {
    --space-#{$i}: calc(#{$i} * var(--space-base));
  }
}
