.u-spacing--text {
  // Default
  > * + * {
    margin-top: 1rem;
  }

  // Intro
  > .o-text--l + * {
    margin-top: 2rem;
  }

  > .o-text--l + .o-text--l {
    margin-top: 1.5rem;
  }

  // Quote
  > .o-blockquote + * {
    margin-top: 3rem;
  }
  > * + .o-blockquote {
    margin-top: 3rem;
  }

  // Headlines
  > .o-hl--1 + * {
    margin-top: 1.5rem;
  }
  > .o-hl--2 + * {
    margin-top: 0.75rem;
  }
  > .o-hl--3 + * {
    margin-top: 0.75rem;
  }
  > .o-hl--4 + * {
    margin-top: 0.75rem;
  }

  > * + .o-hl--1 {
    margin-top: 5rem;
  }
  > * + .o-hl--2 {
    margin-top: 5rem;
  }
  > * + .o-hl--3 {
    margin-top: 3rem;
  }
  > * + .o-hl--4 {
    margin-top: 2rem;
  }

  .o-category + * {
    margin-top: 1.5rem;
  }

  // Button, Links
  > * + .o-button {
    margin-top: 2rem;
  }

  > * + .o-link--external,
  > * + .o-link--download {
    margin-top: 3rem;
  }

  > .o-link--external + *,
  > .o-link--download + * {
    margin-top: 3rem;
  }

  > .o-link--external + .o-link--external,
  > .o-link--download + .o-link--download {
    margin-top: 0.625rem;
  }

  > .o-image + .o-link--download,
  > .o-video + .o-link--download {
    margin-top: 1rem;
  }

  // Image, video
  > .o-image + *,
  > .o-video + * {
    margin-top: 4rem;
  }
  > * + .o-image,
  > * + .o-video {
    margin-top: 4rem;
  }
}

.u-spacing--form {
  > * + * {
    margin-top: 1.5rem;
  }

  > .o-input + .o-checkbox {
    margin-top: 2.5rem;
  }
}

.u-spacing--teaser {
  > * + * {
    margin-top: 1rem;
  }
}
