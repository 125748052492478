.o-checkbox {
  position: relative;
}

.o-checkbox__input {
  width: em(21); // required info bubble is still visible at correct position
  height: em(21);
  position: absolute;
  left: 0;
  top: em(2);
  opacity: 0;
  pointer-events: none;
}

.o-checkbox__label {
  display: inline-flex;
  align-items: flex-start;
  user-select: none;
  cursor: pointer;
}

.o-checkbox__label::before {
  content: '';
  display: inline-block;
  flex-shrink: 0;
  width: em(21);
  height: em(21);
  position: relative;
  top: em(2);
  margin-right: em(10);
  background-color: rgba($color-black, 0.03);
  border: 2px solid $color-black;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 74%;
  outline-offset: -2px;
  outline: 2px solid transparent;
  box-shadow: 0 0 0 transparent;
  transition: background 150ms $ease-fade, box-shadow 150ms $ease-fade;

  // Only show on keyboard focus
  .js-focus-visible .focus-visible.o-checkbox__input:focus + &,
  .no-js .o-checkbox__input:focus + &,
  .o-checkbox__input:focus:invalid + & {
    background-color: $color-white;
    box-shadow: $box-shadow-default;
  }

  .o-checkbox__input:checked + & {
    background-image: url("data:image/svg+xml;charset=uft8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3E%3Cpath fill='none' stroke='currentColor' stroke-width='4' d='M1 13l8 8L23 5'/%3E%3C/svg%3E");
  }
}
