.l-footer {
  background-color: $color-black;
  color: $color-white;
  font-weight: bold;
  font-size: rem(16);
  padding-top: 4rem;
  padding-bottom: 4rem;
  padding-left: var(--padding-x-base);
  padding-right: var(--padding-x-base);

  @media ($min-ms) {
    padding-top: 5rem;
    padding-bottom: 4.5rem;
  }

  @media ($max-m) {
    > * + * {
      margin-top: 5rem;
    }
  }

  @media ($min-m) {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
  }
}

.l-footer__logo {
  display: flex;
  align-items: center;

  svg {
    fill: currentColor;
    height: rem(36);
    width: auto;

    @media ($min-s) {
      height: rem(48);
    }

    @media ($min-ms) {
      height: calc(#{rem(60)} * var(--logo-scale) * 0.01);
    }
  }
}

.l-footer__nav {
  @media ($max-ms) {
    > * + * {
      margin-top: 2.5rem;

      &:last-child {
        margin-top: 4rem;
      }
    }
  }
  @media ($min-ms) {
    display: flex;

    > * + * {
      margin-left: 3.5rem;

      &:last-child {
        margin-left: 6rem;
      }
    }
  }

  li + li {
    margin-top: 0.625rem;
  }

  a {
    position: relative;

    &::after {
      @include extended-clickarea($y: 8);
    }
  }
}
