.l-countdown {
  background-color: $color-gray-bg;
  padding-left: var(--padding-x-base);
  padding-right: var(--padding-x-base);
  padding-top: 8rem;
  display: flex;
  flex-direction: column;
  overflow: hidden;

  @media ($max-ms) {
    height: 150vw;
    max-height: rem(600px);
  }

  @media ($min-ms) {
    height: calc(100vh - 6rem);
    min-height: rem(450);
  }
}

.l-countdown__wrapper {
  //font-variant-numeric: tabular-nums;
  line-height: 0.7;
  position: relative;
  flex-grow: 1;
  font-size: 20vmin;

  @media ($min-ms) {
    font-size: 18vmin;
  }

  @media ($min-ml) {
    font-size: 20vmin;
  }

  > div {
    position: absolute;
    opacity: 0;

    &:nth-child(2),
    &:nth-child(3) {
      writing-mode: tb-rl;
      transform: rotate(-180deg);
    }

    &:nth-child(1) {
      top: 0;
      left: 0;
    }

    &:nth-child(2) {
      bottom: 0;
      left: 12%;

      @media ($min-ms) {
        left: 23%;
      }
    }

    &:nth-child(3) {
      top: 0;
      right: 0;
    }

    &:nth-child(4) {
      bottom: 0;
      right: 12%;

      @media ($min-ms) {
        right: 23%;
      }
    }
  }
}

.l-countdown__text {
  text-align: center;
  margin-top: 3.5rem;
  margin-bottom: 3rem;

  @media ($min-ms) {
    margin-top: 5rem;
    margin-bottom: 4rem;
  }
}
