.u-font--dnom {
  font-feature-settings: 'dnom' on;
}

.u-font--numr {
  font-feature-settings: 'numr' on;
}

.u-font--case {
  font-feature-settings: 'case' on;
}

.u-font--sups {
  font-feature-settings: 'sups' on;
}

.u-font--tnum {
  font-variant-numeric: tabular-nums;
}
