.o-caption {
  @include font-size-fluid($font-size-small);

  &--padding-top {
    padding-top: 0.5rem;

    @media ($min-ms) {
      padding-top: 0.75rem;
    }
  }

  &--padding-x {
    padding-left: var(--padding-x-base);
    padding-right: var(--padding-x-base);
  }
}
