.l-scroll-text {
  position: relative;
}

.l-scroll-text__wrapper {
  overflow-y: hidden;
  overflow-x: scroll;
  @include font-size-fluid((rem(140), rem(350)));
  line-height: 1.47;
  font-weight: 900;
  white-space: nowrap;

  // Hide scrollbar when gsap.draggable is active
  html:not(.no-js) & {
    scrollbar-width: none;

    &::-webkit-scrollbar {
      width: 0;
      height: 0;
    }
  }
}

.l-scroll-text__text {
  padding-left: 0.5ch;
  padding-right: 0.5ch;
  display: inline-block;
  // overwrite draggable cursor
  cursor: url('../img/cursor-gray-1x.png') 24 24, grab !important;
  // prettier-ignore
  cursor: image-set(url('../img/cursor-gray-1x.png') 1x, url('../img/cursor-gray-2x.png') 2x) 24 24, grab !important;
}

.l-scroll-text__lines {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  pointer-events: none;
  z-index: -1;

  svg {
    width: 100%;
    height: 100%;
    stroke: $color-gray-light;
    transform: scaleY(-1); // flip svg
    stroke-width: 8;

    @media ($min-ms) {
      stroke-width: 4;
    }
  }
}
