.l-intro {
  padding-top: 15%;
}

.l-intro__anim {
  display: grid;
  pointer-events: none;
  position: relative;
  z-index: 1;
  transition: opacity 150ms $ease-fade;
  opacity: 0;

  &--visible {
    opacity: 1;
  }

  > * {
    grid-area: 1 / 1 / 2 / 2;
  }
}
