.l-freestyle {
  display: grid;
  grid-template-columns: repeat(12, minmax(0, 1fr));
  grid-auto-rows: auto 3rem;
  align-items: start;

  @media ($min-ms) {
    grid-auto-rows: auto 4rem auto 4rem 10vw auto;
  }
}

.l-freestyle__item:nth-child(1) {
  grid-area: 1 / 1 / span 1 / span 9;

  @media ($min-ms) {
    grid-area: 1 / 1 / span 1 / span 6;
  }
}

.l-freestyle__item:nth-child(2) {
  position: relative;
  z-index: 3;
  grid-area: 3 / 4 / span 1 / span 9;

  @media ($min-ms) {
    grid-area: 1 / 9 / span 1 / span 4;
    align-self: center;
  }
}

.l-freestyle__item:nth-child(3) {
  position: relative;
  z-index: 2;
  grid-area: 5 / 1 / span 1 / span 11;

  @media ($min-ms) {
    grid-area: 3 / 1 / span 1 / span 8;
  }
}

// These 2 items overlay each other by spanning 2 rows
.l-freestyle__item:nth-child(4) {
  position: relative;
  z-index: 1;
  grid-area: 7 / 5 / span 1 / span 8;

  @media ($min-ms) {
    grid-area: 5 / 7 / span 2 / span 6;
  }
}

.l-freestyle__item:nth-child(5) {
  grid-area: 9 / 2 / span 1 / span 8;

  @media ($min-ms) {
    grid-area: 6 / 2 / span 1 / span 8;
  }
}
