.l-article {
  > *:not(.l-article__wide) {
    max-width: rem(640);
  }

  > .o-blockquote {
    margin-left: 1.5rem;

    @media ($min-ms) {
      margin-left: 2rem;
    }
  }

  &--center {
    text-align: center;

    > *:not(.l-article__wide) {
      margin-left: auto;
      margin-right: auto;
    }
  }
}
