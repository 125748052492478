.o-button {
  display: inline-block;
  position: relative;
  font-weight: 900;
  text-align: center;
  color: $color-white;
  background-color: $color-black;
  padding: em(11 30 11);
  transition: transform 300ms $ease-out, background-color 150ms $ease-fade,
    color 150ms $ease-fade;

  &:not([disabled]):hover,
  &:focus {
    background-color: $color-blue;
  }

  &--gray {
    color: $color-black;
    background-color: $color-gray-light;

    &:not([disabled]):hover,
    &:focus {
      color: $color-white;
    }
  }

  &--small {
    padding: em(9 24 8);
  }

  &:not([disabled]):not(&--loading):active {
    transform: scale(0.95);
  }

  &--loading {
    cursor: wait;
  }

  &[disabled]:not(&--loading) {
    cursor: not-allowed;
    opacity: 0.33;
  }
}

.o-button span {
  display: block;
  transition: transform 300ms $ease-fade;
}

.o-button--loading span {
  transform: translateX(em(12));
}

@keyframes button-spinner-anim {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.o-button svg {
  fill: none;
  stroke: currentColor;
  stroke-width: 1.8;
  stroke-linecap: round;
  width: em(18);
  height: em(18);
  position: absolute;
  top: 50%;
  left: em(24);
  margin-top: em(-9);
  margin-left: em(-9);
  opacity: 0;
  transition: opacity 150ms $ease-fade;
}

.o-button--loading svg {
  opacity: 1;
  animation: button-spinner-anim 1s linear infinite;
}
