.c-cookie-consent {
  position: fixed;
  z-index: 20;
  bottom: 0;
  left: 0;
  width: 100%;
  @include font-size-fluid($font-size-small);
  padding-left: var(--padding-x-base);
  padding-right: var(--padding-x-base);
  padding-top: 1rem;
  padding-bottom: 1rem;
  background-color: $color-white;
  color: $color-black;
  box-shadow: $box-shadow-default;
  display: flex;
  flex-wrap: wrap;
  align-items: center;

  @media ($min-ms) {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
  }

  // Anim
  opacity: 0;
  visibility: hidden;
  transition: opacity 300ms $ease-fade 0ms, visibility 0ms linear 300ms;

  &--visible {
    opacity: 1;
    visibility: visible;
    transition-delay: 0ms, 0ms;
  }
}

.c-cookie-consent__text {
  margin-left: auto;
  margin-right: 2rem;

  @media ($max-ms) {
    flex-basis: 100%;
    margin-bottom: 1rem;
  }
}

.c-cookie-consent__button {
  & + & {
    margin-left: 0.75rem;
  }

  &:last-child {
    margin-right: auto;
  }
}
