.o-toggle {
  position: relative;
  width: rem(44);
  height: rem(44);

  @media ($min-ms) {
    width: rem(50);
    height: rem(50);
  }

  &:not(&--button) {
    color: rgba($color-black, 0.3);
  }
}

.o-toggle__input {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
  pointer-events: none;
}

.o-toggle__label {
  display: block;
  user-select: none;
  cursor: pointer;
  padding: rem(11); // svg 22x22
  box-shadow: 0 0 0 transparent;
  transition: color 150ms $ease-fade, background 150ms $ease-fade,
    box-shadow 150ms $ease-fade;

  @media ($min-ms) {
    padding: rem(14); // svg 22x22
  }

  &:hover {
    background-color: rgba($color-black, 0.03);
  }

  &:hover,
  .o-toggle__input:focus + &,
  .o-toggle__input:checked + & {
    color: $color-black;
  }

  // Only show on keyboard focus
  .js-focus-visible .focus-visible.o-toggle__input:focus + &,
  .js-focus-visible .focus-visible.o-toggle--button:focus > &,
  .no-js .o-toggle__input:focus + &,
  .no-js .o-toggle--button:focus > & {
    background-color: $color-white;
    box-shadow: $box-shadow-default;
  }

  svg {
    width: 100%;
    height: 100%;
    fill: currentColor;
  }
}
