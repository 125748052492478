.l-headline {
  @media ($min-ms) {
    text-align: center;
  }

  &--sticky {
    min-height: 6rem;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;

    @media ($min-ms) {
      min-height: 9rem;
    }

    > * {
      position: sticky;
      bottom: 1.5rem;
      width: 100%;
    }
  }
}
