.l-slideshow {
  position: relative;
}

.l-slideshow__wrapper {
  overflow-y: hidden;
  overflow-x: scroll;

  // Hide scrollbar when gsap.draggable is active
  html:not(.no-js) & {
    scrollbar-width: none;

    &::-webkit-scrollbar {
      width: 0;
      height: 0;
    }
  }
}

// Width depends on slide count, no other solution works with gsap.draggable :(
.l-slideshow__slides {
  display: grid;
  width: calc(var(--slide-count) * 100%);
  grid-auto-columns: calc(100% / var(--slide-count));
  grid-auto-flow: column;
  // overwrite draggable cursor
  cursor: url('../img/cursor-white-1x.png') 24 24, grab !important;
  // prettier-ignore
  cursor: image-set(url('../img/cursor-white-1x.png') 1x, url('../img/cursor-white-2x.png') 2x) 24 24, grab !important;
}
