.o-link {
  transition: color 150ms $ease-fade;

  &:hover,
  &:focus {
    color: $color-blue;
  }

  &--underline,
  &--subtle:hover,
  &--subtle:focus {
    text-decoration: underline;
    text-decoration-thickness: em(1);
    text-underline-offset: 2px;
  }

  &--underline {
    &:hover,
    &:focus {
      text-decoration: none;
    }
  }

  &--extended {
    position: relative;

    &::after {
      @include extended-clickarea;
    }
  }

  &--bold {
    font-weight: bold;
  }

  &--external {
    display: table;

    i {
      font-style: normal;
      display: inline-block;
    }
  }

  &--download {
    display: table;
    position: relative;

    // Icon
    svg {
      width: em(18 * 0.8);
      height: em(21 * 0.8);
      fill: currentColor;
      display: inline-block;
      vertical-align: sub;
      margin-right: 0.375em;
    }

    // Filetype
    span::after {
      content: ' (' attr(data-type) ')'; // will be announced by screenreader
      text-transform: uppercase;
    }
  }
}
