html {
  -webkit-text-size-adjust: 100%; // iOS fix
  word-wrap: break-word;
  overflow-wrap: break-word;
  box-sizing: border-box;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

body {
  margin: 0;
}

main {
  display: block; // IE fix
}

b,
strong {
  font-weight: 900;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  font-weight: inherit;
  font-size: inherit;
}

ul,
ol,
dl,
dt,
dd,
p {
  margin: 0;
  padding: 0;
}

ul li,
ol li {
  padding: 0;
  list-style-type: none;
}

blockquote,
figure {
  margin: 0;
}

figcaption {
  font-weight: inherit;
}

img,
video,
picture {
  width: 100%;
  height: auto;
  display: block;
  //vertical-align: top;
}

// video {
//   outline: 0;
// }

iframe {
  border: none;
}

address {
  font-style: normal;
}

svg {
  display: block;
  //vertical-align: top;
}

a {
  text-decoration: none;
  color: inherit;
}

button {
  border: 0;
  border-radius: 0;
  cursor: pointer;
  color: inherit;
  background: none;
  padding: 0;
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  font-weight: inherit;
  line-height: inherit;
  letter-spacing: inherit;
  text-transform: inherit;
}

input,
textarea,
select {
  font-family: inherit;
  font-size: inherit;
  letter-spacing: inherit;
  line-height: inherit;
  text-transform: none;
  appearance: none;
  display: block;
  width: 100%;
  margin: 0;
  border-radius: 0;
}

textarea {
  overflow: auto;
  resize: vertical;
}

label {
  display: block;
}

fieldset {
  padding: 0;
  margin: 0;
  border: none;
}

legend {
  box-sizing: border-box;
  color: inherit;
  display: block;
  width: 100%;
  padding: 0;
  white-space: normal;
}

// Hide outline without degrading a11y,
// High Contrast Mode should still work
a,
button,
video {
  .js-focus-visible &:focus:not(.focus-visible) {
    outline-color: transparent;
    outline-style: solid; // overwrite outline:auto in Blink
  }
}

// A11y
*,
*::before,
*::after {
  @media (prefers-reduced-motion: reduce) {
    animation-duration: 0.001s !important;
    transition-duration: 0.001s !important;
  }
}
