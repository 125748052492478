.o-hl--1 {
  @include font-size-fluid($font-size-hl-1);
  line-height: $line-height-narrow;
  font-weight: bold;
}

.o-hl--2 {
  @include font-size-fluid($font-size-hl-2);
  line-height: $line-height-medium;
  font-weight: bold;
}

.o-hl--3 {
  @include font-size-fluid($font-size-hl-3);
  line-height: $line-height-medium;
  font-weight: bold;
}

.o-hl--4 {
  font-weight: bold;
}
