.l-offices {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 4rem;

  @media ($min-s) {
    grid-template-columns: repeat(auto-fit, minmax(rem(325), 1fr));
  }

  @media ($min-ms) {
    grid-row-gap: 6rem;
  }
}
