@charset "UTF-8";
:root {
  --padding-x-base: 1rem;
  --space-base: 0.75rem;
  --space-1: calc(1 * var(--space-base));
  --space-2: calc(2 * var(--space-base));
  --space-3: calc(3 * var(--space-base));
  --space-4: calc(4 * var(--space-base));
  --space-5: calc(5 * var(--space-base));
  --space-6: calc(6 * var(--space-base));
  --space-7: calc(7 * var(--space-base));
  --space-8: calc(8 * var(--space-base));
  --space-9: calc(9 * var(--space-base));
  --space-10: calc(10 * var(--space-base));
}
@media (min-width: 30em) {
  :root {
    --padding-x-base: 3rem;
  }
}
@media (min-width: 80em) {
  :root {
    --padding-x-base: 5rem;
  }
}
@media (min-width: 45em) {
  :root {
    --space-base: 1rem;
  }
}

@font-face {
  font-family: "Munken Sans Web";
  font-weight: normal;
  font-style: normal;
  font-display: swap;
  src: url("../fnt/munken-sans-regular.woff2") format("woff2"), url("../fnt/munken-sans-regular.woff") format("woff");
}
@font-face {
  font-family: "Munken Sans Web";
  font-weight: 700;
  font-style: normal;
  font-display: swap;
  src: url("../fnt/munken-sans-medium.woff2") format("woff2"), url("../fnt/munken-sans-medium.woff") format("woff");
}
@font-face {
  font-family: "Munken Sans Web";
  font-weight: 900;
  font-style: normal;
  font-display: swap;
  src: url("../fnt/munken-sans-bold.woff2") format("woff2"), url("../fnt/munken-sans-bold.woff") format("woff");
}
html {
  -webkit-text-size-adjust: 100%;
  word-wrap: break-word;
  overflow-wrap: break-word;
  box-sizing: border-box;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

body {
  margin: 0;
}

main {
  display: block;
}

b,
strong {
  font-weight: 900;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  font-weight: inherit;
  font-size: inherit;
}

ul,
ol,
dl,
dt,
dd,
p {
  margin: 0;
  padding: 0;
}

ul li,
ol li {
  padding: 0;
  list-style-type: none;
}

blockquote,
figure {
  margin: 0;
}

figcaption {
  font-weight: inherit;
}

img,
video,
picture {
  width: 100%;
  height: auto;
  display: block;
}

iframe {
  border: none;
}

address {
  font-style: normal;
}

svg {
  display: block;
}

a {
  text-decoration: none;
  color: inherit;
}

button {
  border: 0;
  border-radius: 0;
  cursor: pointer;
  color: inherit;
  background: none;
  padding: 0;
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  font-weight: inherit;
  line-height: inherit;
  letter-spacing: inherit;
  text-transform: inherit;
}

input,
textarea,
select {
  font-family: inherit;
  font-size: inherit;
  letter-spacing: inherit;
  line-height: inherit;
  text-transform: none;
  appearance: none;
  display: block;
  width: 100%;
  margin: 0;
  border-radius: 0;
}

textarea {
  overflow: auto;
  resize: vertical;
}

label {
  display: block;
}

fieldset {
  padding: 0;
  margin: 0;
  border: none;
}

legend {
  box-sizing: border-box;
  color: inherit;
  display: block;
  width: 100%;
  padding: 0;
  white-space: normal;
}

.js-focus-visible a:focus:not(.focus-visible),
.js-focus-visible button:focus:not(.focus-visible),
.js-focus-visible video:focus:not(.focus-visible) {
  outline-color: transparent;
  outline-style: solid;
}

@media (prefers-reduced-motion: reduce) {
  *,
*::before,
*::after {
    animation-duration: 0.001s !important;
    transition-duration: 0.001s !important;
  }
}

::selection {
  background-color: black;
  color: white;
}

html {
  background-color: white;
  color: black;
  font-family: "Munken Sans Web", -system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif;
  font-size: 1em;
  line-height: 1.5;
  letter-spacing: 0;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-variant: no-common-ligatures;
}
@media (max-width: 22.4375em) {
  html {
    hyphens: auto;
  }
}

body {
  min-height: 100vh;
  min-height: calc(var(--vh, 1vh) * 100);
  overflow-y: scroll;
  position: relative;
  display: flex;
  flex-direction: column;
  font-size: 1.125rem;
}
@media (min-width: 30em) {
  body {
    font-size: calc(0.25vw + 1.05rem);
  }
}
@media (min-width: 80em) {
  body {
    font-size: 1.25rem;
  }
}

main {
  flex-grow: 1;
}

.u-a11y--hidden {
  border: 0;
  clip: rect(0 0 0 0);
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  overflow: hidden;
  white-space: nowrap;
}

.u-bg--gray-light {
  background-color: #f7f7f7;
}

.u-spacing--text > * + * {
  margin-top: 1rem;
}
.u-spacing--text > .o-text--l + * {
  margin-top: 2rem;
}
.u-spacing--text > .o-text--l + .o-text--l {
  margin-top: 1.5rem;
}
.u-spacing--text > .o-blockquote + * {
  margin-top: 3rem;
}
.u-spacing--text > * + .o-blockquote {
  margin-top: 3rem;
}
.u-spacing--text > .o-hl--1 + * {
  margin-top: 1.5rem;
}
.u-spacing--text > .o-hl--2 + * {
  margin-top: 0.75rem;
}
.u-spacing--text > .o-hl--3 + * {
  margin-top: 0.75rem;
}
.u-spacing--text > .o-hl--4 + * {
  margin-top: 0.75rem;
}
.u-spacing--text > * + .o-hl--1 {
  margin-top: 5rem;
}
.u-spacing--text > * + .o-hl--2 {
  margin-top: 5rem;
}
.u-spacing--text > * + .o-hl--3 {
  margin-top: 3rem;
}
.u-spacing--text > * + .o-hl--4 {
  margin-top: 2rem;
}
.u-spacing--text .o-category + * {
  margin-top: 1.5rem;
}
.u-spacing--text > * + .o-button {
  margin-top: 2rem;
}
.u-spacing--text > * + .o-link--external,
.u-spacing--text > * + .o-link--download {
  margin-top: 3rem;
}
.u-spacing--text > .o-link--external + *,
.u-spacing--text > .o-link--download + * {
  margin-top: 3rem;
}
.u-spacing--text > .o-link--external + .o-link--external,
.u-spacing--text > .o-link--download + .o-link--download {
  margin-top: 0.625rem;
}
.u-spacing--text > .o-image + .o-link--download,
.u-spacing--text > .o-video + .o-link--download {
  margin-top: 1rem;
}
.u-spacing--text > .o-image + *,
.u-spacing--text > .o-video + * {
  margin-top: 4rem;
}
.u-spacing--text > * + .o-image,
.u-spacing--text > * + .o-video {
  margin-top: 4rem;
}

.u-spacing--form > * + * {
  margin-top: 1.5rem;
}
.u-spacing--form > .o-input + .o-checkbox {
  margin-top: 2.5rem;
}

.u-spacing--teaser > * + * {
  margin-top: 1rem;
}

.u-px--base {
  padding-left: var(--padding-x-base);
  padding-right: var(--padding-x-base);
}

.u-pt--1 {
  padding-top: var(--space-1);
}

.u-pb--1 {
  padding-bottom: var(--space-1);
}

.u-pt--2 {
  padding-top: var(--space-2);
}

.u-pb--2 {
  padding-bottom: var(--space-2);
}

.u-pt--3 {
  padding-top: var(--space-3);
}

.u-pb--3 {
  padding-bottom: var(--space-3);
}

.u-pt--4 {
  padding-top: var(--space-4);
}

.u-pb--4 {
  padding-bottom: var(--space-4);
}

.u-pt--5 {
  padding-top: var(--space-5);
}

.u-pb--5 {
  padding-bottom: var(--space-5);
}

.u-pt--6 {
  padding-top: var(--space-6);
}

.u-pb--6 {
  padding-bottom: var(--space-6);
}

.u-pt--7 {
  padding-top: var(--space-7);
}

.u-pb--7 {
  padding-bottom: var(--space-7);
}

.u-pt--8 {
  padding-top: var(--space-8);
}

.u-pb--8 {
  padding-bottom: var(--space-8);
}

.u-pt--9 {
  padding-top: var(--space-9);
}

.u-pb--9 {
  padding-bottom: var(--space-9);
}

.u-pt--10 {
  padding-top: var(--space-10);
}

.u-pb--10 {
  padding-bottom: var(--space-10);
}

.u-mx--auto {
  margin-left: auto;
  margin-right: auto;
}

.u-mt--base-large {
  margin-top: 9.375rem;
}
@media (min-width: 45em) {
  .u-mt--base-large {
    margin-top: calc(10.625rem + (3.75rem * var(--logo-scale) * 0.01));
  }
}

.u-mt--base-small {
  margin-top: 6.875rem;
}
@media (min-width: 45em) {
  .u-mt--base-small {
    margin-top: calc(7.5rem + (3.75rem * var(--logo-scale) * 0.01));
  }
}

@media (max-width: 44.9375em) {
  .u-mt--base-special {
    margin-top: 6.875rem;
  }
}
@media (max-width: 29.9375em) {
  .u-mt--base-special {
    margin-top: 5rem;
  }
}

.u-mt--1 {
  margin-top: var(--space-1);
}

.u-mb--1 {
  margin-bottom: var(--space-1);
}

.u-mt--2 {
  margin-top: var(--space-2);
}

.u-mb--2 {
  margin-bottom: var(--space-2);
}

.u-mt--3 {
  margin-top: var(--space-3);
}

.u-mb--3 {
  margin-bottom: var(--space-3);
}

.u-mt--4 {
  margin-top: var(--space-4);
}

.u-mb--4 {
  margin-bottom: var(--space-4);
}

.u-mt--5 {
  margin-top: var(--space-5);
}

.u-mb--5 {
  margin-bottom: var(--space-5);
}

.u-mt--6 {
  margin-top: var(--space-6);
}

.u-mb--6 {
  margin-bottom: var(--space-6);
}

.u-mt--7 {
  margin-top: var(--space-7);
}

.u-mb--7 {
  margin-bottom: var(--space-7);
}

.u-mt--8 {
  margin-top: var(--space-8);
}

.u-mb--8 {
  margin-bottom: var(--space-8);
}

.u-mt--9 {
  margin-top: var(--space-9);
}

.u-mb--9 {
  margin-bottom: var(--space-9);
}

.u-mt--10 {
  margin-top: var(--space-10);
}

.u-mb--10 {
  margin-bottom: var(--space-10);
}

.u-mw--medium {
  max-width: 51rem;
}

.u-mw--narrow {
  max-width: 36.25rem;
}

.u-text--center {
  text-align: center;
}

.u-text--right {
  text-align: right;
}

.u-font--dnom {
  font-feature-settings: "dnom" on;
}

.u-font--numr {
  font-feature-settings: "numr" on;
}

.u-font--case {
  font-feature-settings: "case" on;
}

.u-font--sups {
  font-feature-settings: "sups" on;
}

.u-font--tnum {
  font-variant-numeric: tabular-nums;
}

.o-skip-link {
  border: 0;
  clip: rect(0 0 0 0);
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  overflow: hidden;
  white-space: nowrap;
}
.o-skip-link:focus {
  position: absolute;
  top: 3px;
  left: 3px;
  width: auto;
  height: auto;
  clip: auto;
  padding: 0.625em 1.25em;
  z-index: 20;
  background-color: #e6e6e6;
  text-decoration: none;
  box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.1), 3px 2px 12px rgba(0, 0, 0, 0.15);
}

.o-nav-icon {
  width: 100%;
  height: auto;
  display: block;
  fill: none;
  pointer-events: none;
  stroke-width: 2.25;
  stroke: currentColor;
}

.o-nav-icon__line {
  stroke-dasharray: 24;
  stroke-dashoffset: 0;
  opacity: 1;
  transition: stroke-dashoffset 300ms cubic-bezier(0.23, 1, 0.32, 1), opacity 100ms cubic-bezier(0.25, 0, 0.25, 1);
  transition-delay: 200ms, 200ms;
}
.o-nav-icon__line:nth-child(2) {
  transition-delay: 300ms, 300ms;
}
.o-nav-icon__line:nth-child(3) {
  transition-delay: 400ms, 400ms;
}

.o-nav-icon--active .o-nav-icon__line {
  stroke-dashoffset: 24;
  opacity: 0;
  transition-delay: 0ms, 200ms;
}
.o-nav-icon--active .o-nav-icon__line:nth-child(1) {
  transition-delay: 200ms, 400ms;
}
.o-nav-icon--active .o-nav-icon__line:nth-child(2) {
  transition-delay: 100ms, 300ms;
}

.o-nav-icon__cross {
  stroke-dasharray: 33.9411239624;
  stroke-dashoffset: 33.9411239624;
  opacity: 0;
  transition: stroke-dashoffset 300ms cubic-bezier(0.23, 1, 0.32, 1), opacity 100ms cubic-bezier(0.25, 0, 0.25, 1);
  transition-delay: 0ms, 200ms;
}
.o-nav-icon__cross:nth-child(5) {
  transition-delay: 100ms, 300ms;
}

.o-nav-icon--active .o-nav-icon__cross {
  stroke-dashoffset: 0;
  opacity: 1;
  transition-delay: 300ms, 300ms;
}
.o-nav-icon--active .o-nav-icon__cross:nth-child(5) {
  transition-delay: 400ms, 400ms;
}

.o-link {
  transition: color 150ms cubic-bezier(0.25, 0, 0.25, 1);
}
.o-link:hover, .o-link:focus {
  color: #143c9c;
}
.o-link--underline, .o-link--subtle:hover, .o-link--subtle:focus {
  text-decoration: underline;
  text-decoration-thickness: 0.0625em;
  text-underline-offset: 2px;
}
.o-link--underline:hover, .o-link--underline:focus {
  text-decoration: none;
}
.o-link--extended {
  position: relative;
}
.o-link--extended::after {
  content: "";
  width: 100%;
  height: 100%;
  box-sizing: content-box;
  position: absolute;
  padding: 0.375rem 0;
  top: -0.375rem;
  left: 0;
}
.o-link--bold {
  font-weight: bold;
}
.o-link--external {
  display: table;
}
.o-link--external i {
  font-style: normal;
  display: inline-block;
}
.o-link--download {
  display: table;
  position: relative;
}
.o-link--download svg {
  width: 0.875em;
  height: 1.0625em;
  fill: currentColor;
  display: inline-block;
  vertical-align: sub;
  margin-right: 0.375em;
}
.o-link--download span::after {
  content: " (" attr(data-type) ")";
  text-transform: uppercase;
}

.o-hl--1 {
  font-size: 2rem;
  line-height: 1.2;
  font-weight: bold;
}
@media (min-width: 30em) {
  .o-hl--1 {
    font-size: calc(1vw + 1.7rem);
  }
}
@media (min-width: 80em) {
  .o-hl--1 {
    font-size: 2.5rem;
  }
}

.o-hl--2 {
  font-size: 1.5rem;
  line-height: 1.35;
  font-weight: bold;
}
@media (min-width: 30em) {
  .o-hl--2 {
    font-size: calc(0.75vw + 1.275rem);
  }
}
@media (min-width: 80em) {
  .o-hl--2 {
    font-size: 1.875rem;
  }
}

.o-hl--3 {
  font-size: 1.25rem;
  line-height: 1.35;
  font-weight: bold;
}
@media (min-width: 30em) {
  .o-hl--3 {
    font-size: calc(0.5vw + 1.1rem);
  }
}
@media (min-width: 80em) {
  .o-hl--3 {
    font-size: 1.5rem;
  }
}

.o-hl--4 {
  font-weight: bold;
}

.o-list {
  padding-left: 1.25em;
}
.o-list > li {
  position: relative;
}
.o-list > li::before {
  position: absolute;
}
.o-list > li + li {
  margin-top: 0.25em;
}
.o-list--unordered > li::before {
  content: "";
  width: 5px;
  height: 5px;
  background-color: #000;
  border-radius: 50%;
  left: -1em;
  top: 0.6875em;
}
.o-list--ordered {
  list-style-type: none;
  counter-reset: ordered-list;
}
.o-list--ordered > li::before {
  counter-increment: ordered-list;
  content: counter(ordered-list) ".";
  left: -1.25em;
}

.o-image__wrapper {
  position: relative;
}
.o-image__wrapper::after {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: rgba(128, 128, 128, 0.1);
  transition: opacity 1000ms cubic-bezier(0.25, 0, 0.25, 1), visibility 0ms linear 1000ms;
}
.o-image--dark-bg .o-image__wrapper::after {
  background-color: rgba(0, 0, 0, 0.8);
}
.o-image__wrapper--transparent::after {
  opacity: 0;
  visibility: hidden;
}
.o-image__wrapper > img {
  object-fit: cover;
}

.lazyload,
.lazyloading {
  opacity: 0;
}

.lazyloaded {
  transition: opacity 500ms cubic-bezier(0.25, 0, 0.25, 1);
  opacity: 1;
  backface-visibility: hidden;
}

.no-js .lazyload {
  display: none;
}

.o-video__wrapper {
  display: grid;
  position: relative;
}
.o-video__wrapper > * {
  grid-area: 1/1/2/2;
}
.o-video__wrapper::after {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: rgba(128, 128, 128, 0.1);
  transition: opacity 1000ms cubic-bezier(0.25, 0, 0.25, 1), visibility 0ms linear 1000ms;
}
.o-video--dark-bg .o-video__wrapper::after {
  background-color: rgba(0, 0, 0, 0.8);
}
.o-video__wrapper--transparent::after {
  opacity: 0;
  visibility: hidden;
}
.o-video__wrapper svg {
  width: 100%;
  height: auto;
}
.o-video__unmute {
  width: 3rem;
  height: 3rem;
  padding: 0.5rem;
  position: absolute;
  z-index: 1;
  top: 1rem;
  left: 1rem;
  color: white;
  background-color: rgba(0, 0, 0, 0.5);
  transition: opacity 500ms cubic-bezier(0.25, 0, 0.25, 1), visibility 0ms linear, background-color 150ms cubic-bezier(0.25, 0, 0.25, 1);
  opacity: 1;
  visibility: visible;
}
@media (min-width: 45em) {
  .o-video__unmute {
    top: 1.5rem;
    left: 1.5rem;
  }
}
.o-video__unmute:hover, .o-video__unmute:focus {
  background-color: black;
}
.o-video__unmute--hidden {
  opacity: 0;
  visibility: hidden;
}
.o-video__unmute svg {
  fill: currentColor;
  width: 100%;
  height: 100%;
}

.video-lazyload {
  opacity: 0;
}

.video-lazyloaded {
  transition: opacity 500ms cubic-bezier(0.25, 0, 0.25, 1);
  opacity: 1;
  backface-visibility: hidden;
}

.o-lottie {
  display: grid;
}
.o-lottie > * {
  grid-area: 1/1/2/2;
}

.o-caption {
  font-size: 0.9375rem;
}
@media (min-width: 30em) {
  .o-caption {
    font-size: calc(0.125vw + 0.9rem);
  }
}
@media (min-width: 80em) {
  .o-caption {
    font-size: 1rem;
  }
}
.o-caption--padding-top {
  padding-top: 0.5rem;
}
@media (min-width: 45em) {
  .o-caption--padding-top {
    padding-top: 0.75rem;
  }
}
.o-caption--padding-x {
  padding-left: var(--padding-x-base);
  padding-right: var(--padding-x-base);
}

.o-text--l {
  font-size: 1.5rem;
  line-height: 1.35;
}
@media (min-width: 30em) {
  .o-text--l {
    font-size: calc(0.75vw + 1.275rem);
  }
}
@media (min-width: 80em) {
  .o-text--l {
    font-size: 1.875rem;
  }
}

.o-text--s {
  font-size: 0.9375rem;
}
@media (min-width: 30em) {
  .o-text--s {
    font-size: calc(0.125vw + 0.9rem);
  }
}
@media (min-width: 80em) {
  .o-text--s {
    font-size: 1rem;
  }
}

.o-blockquote {
  quotes: "“" "”";
}
.o-blockquote > p::before {
  content: open-quote;
}
.o-blockquote > p::after {
  content: close-quote;
}
.o-blockquote > p {
  font-size: 1.25rem;
}
@media (min-width: 30em) {
  .o-blockquote > p {
    font-size: calc(0.5vw + 1.1rem);
  }
}
@media (min-width: 80em) {
  .o-blockquote > p {
    font-size: 1.5rem;
  }
}
.o-blockquote footer {
  padding-top: 1.25em;
  font-size: 0.9375rem;
  font-weight: 900;
}
@media (min-width: 30em) {
  .o-blockquote footer {
    font-size: calc(0.125vw + 0.9rem);
  }
}
@media (min-width: 80em) {
  .o-blockquote footer {
    font-size: 1rem;
  }
}

.o-category {
  font-weight: bold;
  letter-spacing: 0.05em;
  text-transform: uppercase;
}

.o-input__label {
  margin-bottom: 0.3125em;
  font-size: 0.9375rem;
}
@media (min-width: 30em) {
  .o-input__label {
    font-size: calc(0.125vw + 0.9rem);
  }
}
@media (min-width: 80em) {
  .o-input__label {
    font-size: 1rem;
  }
}
.o-input__label--no-select {
  user-select: none;
}

.o-input__input,
.o-input__select {
  padding: 0.6875rem 0.875rem 0.5625rem;
  border: none;
  border-bottom: 2px solid black;
  color: black;
  background-color: rgba(0, 0, 0, 0.03);
  box-shadow: 0 0 10px inset rgba(0, 0, 0, 0.04);
  transition: background 150ms cubic-bezier(0.25, 0, 0.25, 1), box-shadow 150ms cubic-bezier(0.25, 0, 0.25, 1);
}
@media (min-width: 45em) {
  .o-input__input,
.o-input__select {
    padding: 0.6875rem 1.125rem 0.5625rem;
  }
}
.o-input__input:focus,
.o-input__select:focus {
  outline: 3px solid transparent;
  outline-offset: -3px;
  background-color: white;
  box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.1), 3px 2px 12px rgba(0, 0, 0, 0.15);
}

.o-input__input::placeholder {
  color: rgba(0, 0, 0, 0.2);
  opacity: 1;
}
.o-input__input[type=search]::-webkit-search-decoration {
  appearance: none;
}

.o-input__select {
  background-repeat: no-repeat;
  background-position: right 1em top 1em;
  background-size: 0.9375em 0.5625em;
  background-image: url("data:image/svg+xml;charset=uft8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 22 13'%3E%3Cpolyline fill='none' stroke='currentColor' stroke-width='3' points='1,1 11,11 21,1'/%3E%3C/svg%3E");
}
.o-input__select:invalid {
  color: rgba(0, 0, 0, 0.2);
}
.o-input__select option {
  color: black;
}
.o-input__select::-ms-expand {
  display: none;
}
.o-input__select:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 black;
}

.o-input__range {
  -webkit-appearance: none;
  width: 100%;
  background: transparent;
  height: 1.25rem;
}
.o-input__range::-webkit-slider-thumb {
  cursor: pointer;
  display: block;
  width: 1.125rem;
  height: 1.125rem;
  border: 0;
  border-radius: 50%;
  background-color: black;
  box-shadow: 0 0 0 transparent;
  transition: transform 200ms cubic-bezier(0.23, 1, 0.32, 1), box-shadow 150ms cubic-bezier(0.25, 0, 0.25, 1);
  transform-origin: center;
  -webkit-appearance: none;
  margin-top: -0.5rem;
}
.o-input__range::-moz-range-thumb {
  cursor: pointer;
  display: block;
  width: 1.125rem;
  height: 1.125rem;
  border: 0;
  border-radius: 50%;
  background-color: black;
  box-shadow: 0 0 0 transparent;
  transition: transform 200ms cubic-bezier(0.23, 1, 0.32, 1), box-shadow 150ms cubic-bezier(0.25, 0, 0.25, 1);
  transform-origin: center;
}
.o-input__range::-ms-thumb {
  margin-top: 0;
}
.o-input__range::-webkit-slider-runnable-track {
  width: 100%;
  height: 2px;
  background-color: black;
}
.o-input__range::-moz-range-track {
  width: 100%;
  height: 2px;
  background-color: black;
}
.o-input__range::-ms-track {
  width: 100%;
  height: 2px;
  background-color: black;
}
.o-input__range:focus {
  outline: 0;
}
.js-focus-visible .o-input__range.focus-visible:focus::-webkit-slider-thumb, .no-js .o-input__range:focus::-webkit-slider-thumb {
  background-color: white;
  border: 2px solid black;
  box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.1), 3px 2px 12px rgba(0, 0, 0, 0.15);
  transform: scale(1.1);
}
.js-focus-visible .o-input__range.focus-visible:focus::-moz-range-thumb, .no-js .o-input__range:focus::-moz-range-thumb {
  background-color: white;
  border: 2px solid black;
  box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.1), 3px 2px 12px rgba(0, 0, 0, 0.15);
  transform: scale(1.1);
}
.o-input__range::-moz-focus-outer {
  border: 0;
}

.o-checkbox {
  position: relative;
}

.o-checkbox__input {
  width: 1.3125em;
  height: 1.3125em;
  position: absolute;
  left: 0;
  top: 0.125em;
  opacity: 0;
  pointer-events: none;
}

.o-checkbox__label {
  display: inline-flex;
  align-items: flex-start;
  user-select: none;
  cursor: pointer;
}

.o-checkbox__label::before {
  content: "";
  display: inline-block;
  flex-shrink: 0;
  width: 1.3125em;
  height: 1.3125em;
  position: relative;
  top: 0.125em;
  margin-right: 0.625em;
  background-color: rgba(0, 0, 0, 0.03);
  border: 2px solid black;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 74%;
  outline-offset: -2px;
  outline: 2px solid transparent;
  box-shadow: 0 0 0 transparent;
  transition: background 150ms cubic-bezier(0.25, 0, 0.25, 1), box-shadow 150ms cubic-bezier(0.25, 0, 0.25, 1);
}
.js-focus-visible .focus-visible.o-checkbox__input:focus + .o-checkbox__label::before, .no-js .o-checkbox__input:focus + .o-checkbox__label::before, .o-checkbox__input:focus:invalid + .o-checkbox__label::before {
  background-color: white;
  box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.1), 3px 2px 12px rgba(0, 0, 0, 0.15);
}
.o-checkbox__input:checked + .o-checkbox__label::before {
  background-image: url("data:image/svg+xml;charset=uft8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3E%3Cpath fill='none' stroke='currentColor' stroke-width='4' d='M1 13l8 8L23 5'/%3E%3C/svg%3E");
}

.o-toggle {
  position: relative;
  width: 2.75rem;
  height: 2.75rem;
}
@media (min-width: 45em) {
  .o-toggle {
    width: 3.125rem;
    height: 3.125rem;
  }
}
.o-toggle:not(.o-toggle--button) {
  color: rgba(0, 0, 0, 0.3);
}

.o-toggle__input {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
  pointer-events: none;
}

.o-toggle__label {
  display: block;
  user-select: none;
  cursor: pointer;
  padding: 0.6875rem;
  box-shadow: 0 0 0 transparent;
  transition: color 150ms cubic-bezier(0.25, 0, 0.25, 1), background 150ms cubic-bezier(0.25, 0, 0.25, 1), box-shadow 150ms cubic-bezier(0.25, 0, 0.25, 1);
}
@media (min-width: 45em) {
  .o-toggle__label {
    padding: 0.875rem;
  }
}
.o-toggle__label:hover {
  background-color: rgba(0, 0, 0, 0.03);
}
.o-toggle__label:hover, .o-toggle__input:focus + .o-toggle__label, .o-toggle__input:checked + .o-toggle__label {
  color: black;
}
.js-focus-visible .focus-visible.o-toggle__input:focus + .o-toggle__label, .js-focus-visible .focus-visible.o-toggle--button:focus > .o-toggle__label, .no-js .o-toggle__input:focus + .o-toggle__label, .no-js .o-toggle--button:focus > .o-toggle__label {
  background-color: white;
  box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.1), 3px 2px 12px rgba(0, 0, 0, 0.15);
}
.o-toggle__label svg {
  width: 100%;
  height: 100%;
  fill: currentColor;
}

.o-button {
  display: inline-block;
  position: relative;
  font-weight: 900;
  text-align: center;
  color: white;
  background-color: black;
  padding: 0.6875em 1.875em 0.6875em;
  transition: transform 300ms cubic-bezier(0.23, 1, 0.32, 1), background-color 150ms cubic-bezier(0.25, 0, 0.25, 1), color 150ms cubic-bezier(0.25, 0, 0.25, 1);
}
.o-button:not([disabled]):hover, .o-button:focus {
  background-color: #143c9c;
}
.o-button--gray {
  color: black;
  background-color: #e6e6e6;
}
.o-button--gray:not([disabled]):hover, .o-button--gray:focus {
  color: white;
}
.o-button--small {
  padding: 0.5625em 1.5em 0.5em;
}
.o-button:not([disabled]):not(.o-button--loading):active {
  transform: scale(0.95);
}
.o-button--loading {
  cursor: wait;
}
.o-button[disabled]:not(.o-button--loading) {
  cursor: not-allowed;
  opacity: 0.33;
}

.o-button span {
  display: block;
  transition: transform 300ms cubic-bezier(0.25, 0, 0.25, 1);
}

.o-button--loading span {
  transform: translateX(0.75em);
}

@keyframes button-spinner-anim {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.o-button svg {
  fill: none;
  stroke: currentColor;
  stroke-width: 1.8;
  stroke-linecap: round;
  width: 1.125em;
  height: 1.125em;
  position: absolute;
  top: 50%;
  left: 1.5em;
  margin-top: -0.5625em;
  margin-left: -0.5625em;
  opacity: 0;
  transition: opacity 150ms cubic-bezier(0.25, 0, 0.25, 1);
}

.o-button--loading svg {
  opacity: 1;
  animation: button-spinner-anim 1s linear infinite;
}

.c-nav--active, .c-nav--black {
  color: black;
}

.c-nav__toggle {
  display: block;
  box-sizing: content-box;
  width: 2rem;
  height: 2rem;
  padding: 0.375rem;
  position: fixed;
  z-index: 2;
  top: 1.0625rem;
  transition: color 150ms cubic-bezier(0.25, 0, 0.25, 1);
}
@media (min-width: 30em) {
  .c-nav__toggle {
    top: 1.875rem;
  }
}

.c-nav__wrapper {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  min-height: 100vh;
  min-height: calc(var(--vh, 1vh) * 100);
  background-color: #f7f7f7;
  padding-top: 3rem;
  padding-bottom: 4rem;
  padding-left: var(--padding-x-base);
  padding-right: var(--padding-x-base);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  opacity: 0;
  visibility: hidden;
}
@media (min-width: 30em) {
  .c-nav__wrapper {
    padding-top: 4rem;
    padding-bottom: 5rem;
  }
}
.c-nav--active .c-nav__wrapper {
  opacity: 1;
  visibility: visible;
}

.c-nav__lists > * + * {
  margin-top: 1.75rem;
}
.c-nav__lists li {
  overflow: hidden;
}
.c-nav__lists a {
  position: relative;
  display: inline-block;
}
.c-nav__lists a::after {
  content: "";
  width: 100%;
  height: 100%;
  box-sizing: content-box;
  position: absolute;
  padding: 0.25rem 0;
  top: -0.25rem;
  left: 0;
}
.c-nav__lists a:hover, .c-nav__lists a:focus, .c-nav__lists a.active {
  text-decoration: underline;
  text-decoration-thickness: 0.075em;
  text-underline-offset: 0.1em;
}

.c-nav__primary {
  font-size: 1.875rem;
}
@media (min-width: 30em) {
  .c-nav__primary {
    font-size: 2.5rem;
  }
}

.c-nav__secondary {
  font-size: 1rem;
  font-weight: bold;
}
.c-nav__secondary li + li {
  margin-top: 0.25rem;
}

.c-cookie-consent {
  position: fixed;
  z-index: 20;
  bottom: 0;
  left: 0;
  width: 100%;
  font-size: 0.9375rem;
  padding-left: var(--padding-x-base);
  padding-right: var(--padding-x-base);
  padding-top: 1rem;
  padding-bottom: 1rem;
  background-color: white;
  color: black;
  box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.1), 3px 2px 12px rgba(0, 0, 0, 0.15);
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  opacity: 0;
  visibility: hidden;
  transition: opacity 300ms cubic-bezier(0.25, 0, 0.25, 1) 0ms, visibility 0ms linear 300ms;
}
@media (min-width: 30em) {
  .c-cookie-consent {
    font-size: calc(0.125vw + 0.9rem);
  }
}
@media (min-width: 80em) {
  .c-cookie-consent {
    font-size: 1rem;
  }
}
@media (min-width: 45em) {
  .c-cookie-consent {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
  }
}
.c-cookie-consent--visible {
  opacity: 1;
  visibility: visible;
  transition-delay: 0ms, 0ms;
}

.c-cookie-consent__text {
  margin-left: auto;
  margin-right: 2rem;
}
@media (max-width: 44.9375em) {
  .c-cookie-consent__text {
    flex-basis: 100%;
    margin-bottom: 1rem;
  }
}

.c-cookie-consent__button + .c-cookie-consent__button {
  margin-left: 0.75rem;
}
.c-cookie-consent__button:last-child {
  margin-right: auto;
}

.c-toggle-group__list {
  display: flex;
}

.c-legacy-warning {
  position: fixed;
  z-index: 100;
  top: 6.5rem;
  width: 100%;
  padding: 2rem;
  text-align: center;
  background-color: white;
  border: 4px solid black;
  box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.1), 3px 2px 12px rgba(0, 0, 0, 0.15);
}

.l-header {
  padding-top: 1.5rem;
  padding-left: var(--padding-x-base);
  padding-right: var(--padding-x-base);
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: absolute;
  z-index: 10;
  top: 0;
  left: 0;
  width: 100%;
}
@media (min-width: 30em) {
  .l-header {
    padding-top: 2.25rem;
  }
}
.l-header--white {
  color: white;
}
@media (min-width: 45em) {
  .l-header--white-wide-only {
    color: white;
  }
}

.l-header__logo svg {
  fill: currentColor;
  height: 2.25rem;
  width: auto;
}
@media (min-width: 30em) {
  .l-header__logo svg {
    height: 3rem;
  }
}
@media (min-width: 45em) {
  .l-header__logo svg {
    height: calc(3.75rem * var(--logo-scale) * 0.01);
  }
}

.l-header__nav {
  width: 2.1875rem;
}

.l-footer {
  background-color: black;
  color: white;
  font-weight: bold;
  font-size: 1rem;
  padding-top: 4rem;
  padding-bottom: 4rem;
  padding-left: var(--padding-x-base);
  padding-right: var(--padding-x-base);
}
@media (min-width: 45em) {
  .l-footer {
    padding-top: 5rem;
    padding-bottom: 4.5rem;
  }
}
@media (max-width: 59.9375em) {
  .l-footer > * + * {
    margin-top: 5rem;
  }
}
@media (min-width: 60em) {
  .l-footer {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
  }
}

.l-footer__logo {
  display: flex;
  align-items: center;
}
.l-footer__logo svg {
  fill: currentColor;
  height: 2.25rem;
  width: auto;
}
@media (min-width: 30em) {
  .l-footer__logo svg {
    height: 3rem;
  }
}
@media (min-width: 45em) {
  .l-footer__logo svg {
    height: calc(3.75rem * var(--logo-scale) * 0.01);
  }
}

@media (max-width: 44.9375em) {
  .l-footer__nav > * + * {
    margin-top: 2.5rem;
  }
  .l-footer__nav > * + *:last-child {
    margin-top: 4rem;
  }
}
@media (min-width: 45em) {
  .l-footer__nav {
    display: flex;
  }
  .l-footer__nav > * + * {
    margin-left: 3.5rem;
  }
  .l-footer__nav > * + *:last-child {
    margin-left: 6rem;
  }
}
.l-footer__nav li + li {
  margin-top: 0.625rem;
}
.l-footer__nav a {
  position: relative;
}
.l-footer__nav a::after {
  content: "";
  width: 100%;
  height: 100%;
  box-sizing: content-box;
  position: absolute;
  padding: 0.5rem 0;
  top: -0.5rem;
  left: 0;
}

@media (min-width: 45em) and (min-aspect-ratio: 4/3) {
  .l-hero img {
    height: calc(100vh - 6rem);
    min-height: 28.125rem;
  }
}

.l-article > *:not(.l-article__wide) {
  max-width: 40rem;
}
.l-article > .o-blockquote {
  margin-left: 1.5rem;
}
@media (min-width: 45em) {
  .l-article > .o-blockquote {
    margin-left: 2rem;
  }
}
.l-article--center {
  text-align: center;
}
.l-article--center > *:not(.l-article__wide) {
  margin-left: auto;
  margin-right: auto;
}

.l-teaser {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 4rem 3rem;
}
@media (min-width: 45em) {
  .l-teaser {
    grid-template-columns: repeat(auto-fit, minmax(27.5rem, 1fr));
    grid-row-gap: 6rem;
  }
}

.l-teaser__item {
  position: relative;
}

.l-teaser__link::after {
  content: "";
  width: 100%;
  height: 100%;
  box-sizing: content-box;
  position: absolute;
  padding: 0 0;
  top: 0;
  left: 0;
}

.l-teaser__more {
  font-weight: bold;
  text-decoration: underline;
}
.l-teaser__item:hover .l-teaser__more, .l-teaser__item:focus-within .l-teaser__more {
  text-decoration: none;
  color: #143c9c;
}

.l-offices {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 4rem;
}
@media (min-width: 30em) {
  .l-offices {
    grid-template-columns: repeat(auto-fit, minmax(20.3125rem, 1fr));
  }
}
@media (min-width: 45em) {
  .l-offices {
    grid-row-gap: 6rem;
  }
}

@media (min-width: 45em) {
  .l-headline {
    text-align: center;
  }
}
.l-headline--sticky {
  min-height: 6rem;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}
@media (min-width: 45em) {
  .l-headline--sticky {
    min-height: 9rem;
  }
}
.l-headline--sticky > * {
  position: sticky;
  bottom: 1.5rem;
  width: 100%;
}

.l-slideshow {
  position: relative;
}

.l-slideshow__wrapper {
  overflow-y: hidden;
  overflow-x: scroll;
}
html:not(.no-js) .l-slideshow__wrapper {
  scrollbar-width: none;
}
html:not(.no-js) .l-slideshow__wrapper::-webkit-scrollbar {
  width: 0;
  height: 0;
}

.l-slideshow__slides {
  display: grid;
  width: calc(var(--slide-count) * 100%);
  grid-auto-columns: calc(100% / var(--slide-count));
  grid-auto-flow: column;
  cursor: url("../img/cursor-white-1x.png") 24 24, grab !important;
  cursor: image-set(url("../img/cursor-white-1x.png") 1x, url("../img/cursor-white-2x.png") 2x) 24 24, grab !important;
}

.l-intro {
  padding-top: 15%;
}

.l-intro__anim {
  display: grid;
  pointer-events: none;
  position: relative;
  z-index: 1;
  transition: opacity 150ms cubic-bezier(0.25, 0, 0.25, 1);
  opacity: 0;
}
.l-intro__anim--visible {
  opacity: 1;
}
.l-intro__anim > * {
  grid-area: 1/1/2/2;
}

.l-scroll-text {
  position: relative;
}

.l-scroll-text__wrapper {
  overflow-y: hidden;
  overflow-x: scroll;
  font-size: 8.75rem;
  line-height: 1.47;
  font-weight: 900;
  white-space: nowrap;
}
@media (min-width: 30em) {
  .l-scroll-text__wrapper {
    font-size: calc(26.25vw + 0.875rem);
  }
}
@media (min-width: 80em) {
  .l-scroll-text__wrapper {
    font-size: 21.875rem;
  }
}
html:not(.no-js) .l-scroll-text__wrapper {
  scrollbar-width: none;
}
html:not(.no-js) .l-scroll-text__wrapper::-webkit-scrollbar {
  width: 0;
  height: 0;
}

.l-scroll-text__text {
  padding-left: 0.5ch;
  padding-right: 0.5ch;
  display: inline-block;
  cursor: url("../img/cursor-gray-1x.png") 24 24, grab !important;
  cursor: image-set(url("../img/cursor-gray-1x.png") 1x, url("../img/cursor-gray-2x.png") 2x) 24 24, grab !important;
}

.l-scroll-text__lines {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  pointer-events: none;
  z-index: -1;
}
.l-scroll-text__lines svg {
  width: 100%;
  height: 100%;
  stroke: #e6e6e6;
  transform: scaleY(-1);
  stroke-width: 8;
}
@media (min-width: 45em) {
  .l-scroll-text__lines svg {
    stroke-width: 4;
  }
}

.l-freestyle {
  display: grid;
  grid-template-columns: repeat(12, minmax(0, 1fr));
  grid-auto-rows: auto 3rem;
  align-items: start;
}
@media (min-width: 45em) {
  .l-freestyle {
    grid-auto-rows: auto 4rem auto 4rem 10vw auto;
  }
}

.l-freestyle__item:nth-child(1) {
  grid-area: 1/1/span 1/span 9;
}
@media (min-width: 45em) {
  .l-freestyle__item:nth-child(1) {
    grid-area: 1/1/span 1/span 6;
  }
}

.l-freestyle__item:nth-child(2) {
  position: relative;
  z-index: 3;
  grid-area: 3/4/span 1/span 9;
}
@media (min-width: 45em) {
  .l-freestyle__item:nth-child(2) {
    grid-area: 1/9/span 1/span 4;
    align-self: center;
  }
}

.l-freestyle__item:nth-child(3) {
  position: relative;
  z-index: 2;
  grid-area: 5/1/span 1/span 11;
}
@media (min-width: 45em) {
  .l-freestyle__item:nth-child(3) {
    grid-area: 3/1/span 1/span 8;
  }
}

.l-freestyle__item:nth-child(4) {
  position: relative;
  z-index: 1;
  grid-area: 7/5/span 1/span 8;
}
@media (min-width: 45em) {
  .l-freestyle__item:nth-child(4) {
    grid-area: 5/7/span 2/span 6;
  }
}

.l-freestyle__item:nth-child(5) {
  grid-area: 9/2/span 1/span 8;
}
@media (min-width: 45em) {
  .l-freestyle__item:nth-child(5) {
    grid-area: 6/2/span 1/span 8;
  }
}

.l-countdown {
  background-color: #f7f7f7;
  padding-left: var(--padding-x-base);
  padding-right: var(--padding-x-base);
  padding-top: 8rem;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}
@media (max-width: 44.9375em) {
  .l-countdown {
    height: 150vw;
    max-height: 37.5rem;
  }
}
@media (min-width: 45em) {
  .l-countdown {
    height: calc(100vh - 6rem);
    min-height: 28.125rem;
  }
}

.l-countdown__wrapper {
  line-height: 0.7;
  position: relative;
  flex-grow: 1;
  font-size: 20vmin;
}
@media (min-width: 45em) {
  .l-countdown__wrapper {
    font-size: 18vmin;
  }
}
@media (min-width: 80em) {
  .l-countdown__wrapper {
    font-size: 20vmin;
  }
}
.l-countdown__wrapper > div {
  position: absolute;
  opacity: 0;
}
.l-countdown__wrapper > div:nth-child(2), .l-countdown__wrapper > div:nth-child(3) {
  writing-mode: tb-rl;
  transform: rotate(-180deg);
}
.l-countdown__wrapper > div:nth-child(1) {
  top: 0;
  left: 0;
}
.l-countdown__wrapper > div:nth-child(2) {
  bottom: 0;
  left: 12%;
}
@media (min-width: 45em) {
  .l-countdown__wrapper > div:nth-child(2) {
    left: 23%;
  }
}
.l-countdown__wrapper > div:nth-child(3) {
  top: 0;
  right: 0;
}
.l-countdown__wrapper > div:nth-child(4) {
  bottom: 0;
  right: 12%;
}
@media (min-width: 45em) {
  .l-countdown__wrapper > div:nth-child(4) {
    right: 23%;
  }
}

.l-countdown__text {
  text-align: center;
  margin-top: 3.5rem;
  margin-bottom: 3rem;
}
@media (min-width: 45em) {
  .l-countdown__text {
    margin-top: 5rem;
    margin-bottom: 4rem;
  }
}

.l-text-image-columns {
  display: grid;
  grid-gap: 4rem;
}
@media (min-width: 45em) {
  .l-text-image-columns {
    grid-template-columns: repeat(2, minmax(0, 1fr));
    grid-gap: 6rem;
    align-items: stretch;
  }
}

@media (min-width: 45em) {
  .l-text-image-columns__text {
    position: sticky;
    top: 0;
    min-height: 100vh;
    display: flex;
    align-items: center;
  }
}

@media (min-width: 45em) {
  .l-text-image-columns__images {
    padding: calc((100vh - var(--height, 80vh)) / 2) 0;
  }
}
.l-text-image-columns__images > * {
  position: sticky;
  top: 4rem;
}
@media (min-width: 45em) {
  .l-text-image-columns__images > * {
    width: 65vmin;
    max-width: 100%;
    margin-left: auto;
    margin-right: auto;
    top: calc((100vh - var(--height, 80vh)) / 2);
  }
}