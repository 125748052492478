.l-header {
  padding-top: 1.5rem;
  padding-left: var(--padding-x-base);
  padding-right: var(--padding-x-base);
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: absolute;
  z-index: 10;
  top: 0;
  left: 0;
  width: 100%;

  @media ($min-s) {
    padding-top: 2.25rem;
  }

  &--white {
    color: $color-white;
  }

  &--white-wide-only {
    @media ($min-ms) {
      color: $color-white;
    }
  }
}

.l-header__logo {
  svg {
    fill: currentColor;
    height: rem(36);
    width: auto;

    @media ($min-s) {
      height: rem(48);
    }

    @media ($min-ms) {
      height: calc(#{rem(60)} * var(--logo-scale) * 0.01);
    }
  }
}

.l-header__nav {
  width: rem(35); // Same as logo
}
