::selection {
  background-color: $color-black;
  color: $color-white;
}

html {
  background-color: $color-white;
  color: $color-black;
  font-family: $font-family-default;
  font-size: 1em;
  line-height: $line-height-default;
  letter-spacing: $letter-spacing-default;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-variant: no-common-ligatures;

  // prevent long word wrap
  @media ($max-xs) {
    hyphens: auto;
  }
}

body {
  min-height: 100vh;
  min-height: calc(var(--vh, 1vh) * 100); // custom --vh to fix iOS display
  overflow-y: scroll; // always show scrollbar
  position: relative;
  display: flex;
  flex-direction: column;
  // margin-left: auto;
  // margin-right: auto;
  // max-width: $width-body;
  @include font-size-fluid($font-size-default);
}

main {
  flex-grow: 1;
}
